import React from 'react';
import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';
import RootComponent from '../ClientData/ClientDataStructure';
import './types/tsarray';
import '@ungap/global-this'; //IE11 globaThis polyfill

globalThis.React = React;
globalThis.Components = { RootComponent };
globalThis.ReactDOM = ReactDOM;
globalThis.ReactDOMServer = ReactDOMServer;
