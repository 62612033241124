import React from 'react';
import Tippy from '@tippyjs/react';

import { ClientDataObject } from './types/StructureTypes';
import BrickState, { BrickProps } from './types/BrickTypes';
import ClientDataContextObject from './ClientDataContext';
import functions from './ClientDataFunctions';
import generalFunctions from '../common/helpers/functions';
import { Action, BrickValidatorType } from './types/DTOs';

export default class ClientDataTextBrick extends React.Component<BrickProps, BrickState> {
    static contextType = ClientDataContextObject;
    context: ClientDataObject;
    labelid: string;
    emptyImgSrc: string;
    previousNotSynchedValue: string;
    tooltip: any;

    constructor(props) {
        super(props);

        const existingBrick = this.props.dataToValidate.firstOrDefault(x => x.key === this.props.brick.key);
        let initialValue = this.props.brick.value ?? '';
        let initialAction = Action.hide;
        this.previousNotSynchedValue = initialValue;

        if (this.props.brick.previousModification && this.props.brick.previousModification.Value) {
            initialValue = this.props.brick.previousModification.Value;
            if (this.props.brick.previousModification.Action === 'Show' && existingBrick.action === 'Show') {
                initialAction = Action.show
            }
        }

        if (existingBrick) {
            initialAction = existingBrick.action;
            initialValue = existingBrick.value;
        }

        this.state = {
            value: initialValue ?? '',
            label: '',
            touched: false,
            hasErrors: false,
            action: initialAction
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.emptyImgSrc = generalFunctions.urlContent(this.props.appPath, '/Content/Images/icons/img_trans.gif');

        this.tooltip = React.createRef();
    }

    async componentDidUpdate(prevProps: BrickProps, prevState: BrickState) {
        if (functions.checkIfBrickNeedsUpdate(prevProps, this.props, prevState, this.state)) {
            await this.handleChange(this.state.value, prevProps.touch !== this.props.touch);
        }
    }

    shouldComponentUpdate(nextProps: BrickProps, nextState: BrickState) {
        return functions.checkIfBrickNeedsRerender(this.props, nextProps, this.state, nextState);
    }

    async handleChange(value: string, submit: boolean) {
        const thisBrick = this.props.dataToValidate.firstOrDefault(b => b.key === this.props.brick.key);
        if (thisBrick.action === Action.markAsRequired) {
            const requiredValidator = this.props.brick.validators.find(v => v.type === BrickValidatorType.required);
            if (!requiredValidator) {
                this.props.brick.validators.push({
                    type: BrickValidatorType.required,
                    rule: null,
                    bricksForValidation: null,
                    errorMessage: thisBrick.triggerMessage
                });
            } else {
                requiredValidator.errorMessage = thisBrick.triggerMessage;
            }
        }
        if (thisBrick.action === Action.markAsOptional) {

            this.props.brick.validators = this.props.brick.validators.filter(v => v.type !== BrickValidatorType.required);
        }
        let errorMessages = await functions.validateBrickAsync(this.props.brick, value, this.props.dataToValidate, this.props.appPath);

        if (thisBrick.action === Action.hide) {
            value = this.props.brick.value;
            errorMessages = new Set<string>();
        }

        this.setState(
            {
                action: thisBrick.action,
                touched: true,
                hasErrors: errorMessages.size > 0 || (thisBrick && thisBrick.hasErrors),
            },
            () => this.context.updateDataToSend(this.props.brick.key, value, errorMessages, submit));

    }

    handleClickOutside() {
        this.tooltip.current._tippy.hide();
    }

    render() {
        if (this.state.action === Action.hide) {
            return null;
        }
        return (
            <div className="l-section l-sectionReadonly">
                <div className="l-sectionLeft">
                    <span className="l-section-text">
                        {this.props.brick.previousModification &&
                            <Tippy trigger="focus" arrow={true} theme="quipu" maxWidth="225" delay={200} animation="shift-away" hideOnClick={false} content={this.context.translations.WarningUpdatePendingInField.replace('{0}', this.previousNotSynchedValue).replace('{1}', this.props.brick.previousModification.Value)}>
                                <img src={this.emptyImgSrc} className="pointer border0 imgWarning valignmiddle inline-ico" tabIndex={0} />
                            </Tippy>
                        }
                        <label className={(this.props.brick.readOnly ? 'readonly' : '')} htmlFor={this.props.brick.key}>{this.props.brick.title}</label>
                        {this.props.brick.tooltip &&
                            <Tippy ref={this.tooltip} trigger="focus" interactive={true} arrow={true} theme="quipu" maxWidth="225" delay={200} animation="shift-away" hideOnClick={false} content={<span tabIndex={-1} onClick={() => this.handleClickOutside()} dangerouslySetInnerHTML={{ __html: this.props.brick.tooltip.replace('<a', '<a tabindex="-1"') }} />}>
                                <img src={this.emptyImgSrc} className="pointer border0 tooltip-ico valignmiddle" tabIndex={0} />
                            </Tippy>
                        }
                    </span>
                </div>
                <div className="l-sectionRight">
                    {!this.props.brick.readOnly &&
                        <input id={this.props.brick.key} type="text" value={this.state.value} onChange={e => this.setState({ value: e.target.value }) } onBlur={async e => await this.handleChange(e.target.value, false)} />
                    }
                    {this.props.brick.readOnly &&
                        <span className="l-section-text">
                            {this.state.value}
                        </span>
                    }
                    {this.state.touched && this.state.hasErrors && <span className="field-validation-error">*</span>}
                </div>
            </div>
        );
    }
}
