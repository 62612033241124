import React from 'react';
import Tippy from '@tippyjs/react';
import ClientDataContextObject from './ClientDataContext';
import functions from './ClientDataFunctions';
import generalFunctions from '../common/helpers/functions';
import { Action, BrickValidatorType } from './types/DTOs';

export default class ClientDataDateTimeBrick extends React.Component {
    static contextType = ClientDataContextObject;
    static previousNotSynchedValue;

    constructor(props) {
        super(props);

        const existingBrick = this.props.dataToValidate.firstOrDefault(x => x.key === this.props.brick.key);
        let initialValue = this.props.brick.value ?? '';
        let initialAction = Action.hide;
        this.previousNotSynchedValue = initialValue;

        if (this.props.brick.previousModification && this.props.brick.previousModification.Value) {
            initialValue = this.props.brick.previousModification.Value;
            if (this.props.brick.previousModification.Action === 'Show' && existingBrick.action === 'Show') {
                initialAction = Action.show;
            }
        }

        if (existingBrick) {
            initialAction = existingBrick.action;
            initialValue = existingBrick.value;
        }

        this.state = {
            value: initialValue ?? '',
            label: '',
            touched: false,
            validationMessages: new Set(),
            action: initialAction,
            dateInit: false,
        };
        this.dateTimeField = React.createRef();
        this.tooltip = React.createRef();
        this.handleChange = this.handleChange.bind(this);
        this.labelHandler = this.labelHandler.bind(this);
        this.emptyImgSrc = generalFunctions.urlContent(this.props.appPath, '/Content/Images/icons/img_trans.gif');

    }

    componentDidMount() {
        if (!this.state.dateInit) {
            this.setState({
                dateInit: true
            }, () => {
                this.dateTimeInit();
            });
        }

        if (this.state.action === 'Hide') {
            this.setState({
                dateInit: false,
            });
            return;
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        if (functions.checkIfBrickNeedsUpdate(prevProps, this.props, prevState, this.state)) {
            await this.handleChange(this.state.value, prevProps.touch !== this.props.touch);
        }
        if ((this.state.action === 'Show' || this.state.action) && !this.state.dateInit) {
            this.setState({
                dateInit: true
            }, () => {
                this.dateTimeInit();
            });
        }

        if (this.state.action === 'Hide') {
            this.setState({
                dateInit: false,
            });
            return;
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return functions.checkIfBrickNeedsRerender(this.props, nextProps, this.state, nextState);
    }

    dateTimeInit() {
        const self = this;
        if (!self.dateTimeField.current) {
            return;
        }

        const lowerThan = this.props.brick.validators.firstOrDefault(validator => validator.type === BrickValidatorType.lowerThan);
        const lowerThanOrEqual = this.props.brick.validators.firstOrDefault(validator => validator.type === BrickValidatorType.lowerThanOrEqual);
        let lowerThanValue = null;
        if (lowerThan) {
            const minDateHelper = functions.toDate(this.props.brick.format, lowerThan.rule);
            lowerThanValue = minDateHelper.setDate(minDateHelper.getDate() - 1);
        }
        if (lowerThanOrEqual) {
            lowerThanValue = functions.toDate(this.props.brick.format, lowerThanOrEqual.rule);
        }

        const greaterThan = this.props.brick.validators.firstOrDefault(validator => validator.type === BrickValidatorType.greaterThan);
        const greaterThanOrEqual = this.props.brick.validators.firstOrDefault(validator => validator.type === BrickValidatorType.greaterThanOrEqual);
        let greaterThanValue = null;
        if (greaterThan) {
            const maxDateHelper = functions.toDate(this.props.brick.format, greaterThan.rule);
            greaterThanValue = maxDateHelper.setDate(maxDateHelper.getDate() + 1);
        }
        if (greaterThanOrEqual) {
            greaterThanValue = functions.toDate(this.props.brick.format, greaterThanOrEqual.rule);
        }

        $(this.dateTimeField.current).datepicker(
            {
                dateFormat: this.context.datePickerFormat,
                changeMonth: true,
                changeYear: true,
                minDate: greaterThanValue,
                yearRange: 'c-100:c+10',
                maxDate: lowerThanValue,
                onSelect: async function (value) { await self.handleChange(value, false); }
            });
        if (this.state.value) {
            $(self.dateTimeField.current).datepicker('setDate', functions.toDate(this.props.brick.format, this.state.value));
            this.setState({
                value: $(self.dateTimeField.current).val()
            }, () => self.context.updateDataToSend(self.props.brick.key, self.state.value, null, false, true));
        }
        $(self.dateTimeField.current).toggle(!this.props.brick.readOnly);
    }

    async handleChange(value, submit) {
        const thisBrick = this.props.dataToValidate.firstOrDefault(b => b.key === this.props.brick.key);

        if (thisBrick.action === Action.markAsRequired) {
            const requiredValidator = this.props.brick.validators.find(v => v.type === BrickValidatorType.required);
            if (!requiredValidator) {
                this.props.brick.validators.push({
                    type: BrickValidatorType.required,
                    rule: null,
                    bricksForValidation: null,
                    errorMessage: thisBrick.triggerMessage
                });
            } else {
                requiredValidator.errorMessage = thisBrick.triggerMessage;
            }
        }
        if (thisBrick.action === Action.markAsOptional) {

            this.props.brick.validators = this.props.brick.validators.filter(v => v.type !== BrickValidatorType.required);
        }
        let errorMessages = await functions.validateBrickAsync(this.props.brick, value, this.props.dataToValidate);

        if (value) {
            const parsedValue = functions.toDate(this.props.brick.format, value);
            if (!generalFunctions.isValidDate(parsedValue)) {
                errorMessages.add(this.context.translations.InvalidDate);
            }
        }

        if (thisBrick.action === Action.hide) {
            value = this.props.brick.value;
            errorMessages = new Set();
        }

        this.setState(
            {
                action: thisBrick.action,
                touched: true,
                value: value,
                hasErrors: errorMessages.size > 0 || (thisBrick && thisBrick.hasErrors),
            },
            () => this.context.updateDataToSend(this.props.brick.key, value, errorMessages, submit));
    }

    labelHandler() {
        if (!this.props.brick.readOnly) {
            $(this.dateTimeField.current).datepicker('show');
        }
    }

    handleClickOutside() {
        this.tooltip.current._tippy.hide();
    }

    render() {
        if (this.state.action === Action.hide) {
            return null;
        }
        return (
            <div className="l-section l-sectionReadonly">
                <div className="l-sectionLeft">
                    <span className="l-section-text">
                        {this.props.brick.previousModification &&
                            <Tippy trigger="focus" arrow={true} theme="quipu" maxWidth="225" delay={200} animation="shift-away" hideOnClick={false} content={this.context.translations.WarningUpdatePendingInField.replace('{0}', this.previousNotSynchedValue).replace('{1}', this.props.brick.previousModification.Value)}>
                                <img src={this.emptyImgSrc} className="pointer border0 imgWarning valignmiddle inline-ico" tabIndex={0} />
                            </Tippy>
                        }
                        <label className={(this.props.brick.readOnly ? 'readonly' : '')} onClick={() => this.labelHandler()}>{this.props.brick.title}</label>
                        {this.props.brick.tooltip &&
                            <Tippy ref={this.tooltip} trigger="focus" interactive={true} arrow={true} theme="quipu" maxWidth="225" delay={200} animation="shift-away" hideOnClick={false} content={<span onClick={() => this.handleClickOutside()} dangerouslySetInnerHTML={{ __html: this.props.brick.tooltip.replace('<a', '<a tabindex="-1"') }} />}>
                                <img src={this.state.emptyImgSrc} className="pointer border0 tooltip-ico valignmiddle" tabIndex={0} />
                            </Tippy>
                        }
                    </span>
                </div>
                <div className="l-sectionRight">
                    <input type="text" className={(this.props.brick.readOnly ? 'is-hidden' : '')} onChange={async e => await this.handleChange(e.target.value, false)} ref={this.dateTimeField} />
                    {this.props.brick.readOnly &&
                        <span className="l-section-text">
                            {this.state.value}
                        </span>
                    }
                    {this.state.touched && this.state.hasErrors > 0 && <span className="field-validation-error">*</span>}
                </div>
            </div>
        );
    }
}

