import React from 'react';
import generalFunctions from '../common/helpers/functions';
import ClientDataContextObject from './ClientDataContext';

export default class FileUploadListItem extends React.Component<any, any> {
    static contextType = ClientDataContextObject;
    emptyImgSrc: string;

    constructor(props) {
        super(props);
        this.emptyImgSrc = generalFunctions.urlContent(this.props.appPath, '/Content/Images/icons/img_trans.gif');
    }

    handleFileDeletion = () => {
        this.props.handleFileDeletion(this.props.file)
    }

    render() {
        return (
            <li>
                {this.props.file.Name && <span className={this.props.file.ErrorMessage ? 'marginright field-validation-error' : 'marginright'}>{this.props.file.Name}</span>}
                {this.props.file.ErrorMessage && <span className="field-validation-error">{this.props.file.ErrorMessage}</span>}
                <img src={this.emptyImgSrc} className="imgDelete valignmiddle" onClick={() => this.handleFileDeletion()} />
            </li>
        );
    }
}