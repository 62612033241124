import React from 'react';
import { ClientDataObject } from './types/StructureTypes';

const ClientDataContextObject = React.createContext<ClientDataObject>({
    updateDataToSend: () => { },
    translations: [],
    datePickerFormat: '',
    antiForgeryToken: '',
    appPath: '',
    fileUploadValidation: {
        maxFileSize: 0,
        supportedExtensions: ''
    }
});

export default ClientDataContextObject;