import React from 'react';
import Tippy from '@tippyjs/react';
import { v4 as uuidv4 } from 'uuid';
import { ClientDataObject } from './types/StructureTypes';
import ClientDataContextObject from './ClientDataContext';
import functions from './ClientDataFunctions';
import FileUploadListItem from './FileUploadListItem';
import { BrickProps, FileUploadBrickState } from './types/BrickTypes';
import generalFunctions from '../common/helpers/functions';
import { Action, BrickValidatorType } from './types/DTOs';
/*eslint prefer-spread: "off"*/

export default class ClientDataFileUploadBrick extends React.Component<BrickProps, FileUploadBrickState> {
    static contextType = ClientDataContextObject;
    context: ClientDataObject;
    labelid: string;
    emptyImgSrc: string;
    isFileTypeSupported: (string) => boolean;
    uploadUrl: string;
    fileInput: React.RefObject<HTMLInputElement>;
    previousNotSynchedValue: string;
    tooltip: any;

    constructor(props) {
        super(props);

        const existingBrick = this.props.dataToValidate.firstOrDefault(x => x.key === this.props.brick.key);
        let initialValue = this.props.brick.value ?? '';
        let initialAction = Action.hide;
        this.previousNotSynchedValue = initialValue;

        if (this.props.brick.previousModification && this.props.brick.previousModification.Value) {
            initialValue = this.props.brick.previousModification.Value;
            if (this.props.brick.previousModification.Action === 'Show' && existingBrick.action === 'Show') {
                initialAction = Action.show
            }
        }

        if (existingBrick) {
            initialAction = existingBrick.action;
            initialValue = existingBrick.value;
        }

        this.state = {
            value: initialValue ?? '',
            label: '',
            touched: false,
            hasErrors: false,
            files: !initialValue ? [] : JSON.parse(initialValue),
            loadingFiles: false,
            supportedFileTypes: [],
            action: initialAction,
        };
        this.fileInput = React.createRef();
        this.tooltip = React.createRef();
        this.handleFileDeletion = this.handleFileDeletion.bind(this);
        this.handleFileUpload = this.handleFileUpload.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.uploadUrl = generalFunctions.urlContent(this.props.appPath, '/File/UploadFile');
        this.emptyImgSrc = generalFunctions.urlContent(this.props.appPath, '/Content/Images/icons/img_trans.gif');
        this.isFileTypeSupported = (extension) => this.state.supportedFileTypes.exist(s => s === extension);
    }

    componentDidMount() {
        this.setState({
            supportedFileTypes: [].concat.apply([], this.context.fileUploadValidation.supportedExtensions.split('|').map(f => f.split(';'))).map(e => e.substring(e.indexOf('*') + 2).replace(')', ''))
        });
    }

    async componentDidUpdate(prevProps: BrickProps, prevState: FileUploadBrickState) {
        if (functions.checkIfBrickNeedsUpdate(prevProps, this.props, prevState, this.state)) {
            await this.handleChange(this.state.value, prevProps.touch !== this.props.touch);
        }
    }

    shouldComponentUpdate(nextProps: BrickProps, nextState: FileUploadBrickState) {
        if (this.state.loadingFiles !== nextState.loadingFiles) {
            return true;
        }
        if (this.state.supportedFileTypes !== nextState.supportedFileTypes) {
            return true;
        }
        if (this.state.files.length !== nextState.files.length) {
            return true;
        }

        return functions.checkIfBrickNeedsRerender(this.props, nextProps, this.state, nextState);
    }
    async handleFileDeletion(file) {
        const filteredFileList = this.state.files.filter((fileToFilter) => fileToFilter.UniqueName !== file.UniqueName);
        await this.handleChange(JSON.stringify(filteredFileList), false);
        this.setState({
            files: filteredFileList,
        });
    }

    async handleChange(value: string, submit: boolean) {
        const thisBrick = this.props.dataToValidate.firstOrDefault(b => b.key === this.props.brick.key);
        if (thisBrick.action === Action.markAsRequired) {
            const requiredValidator = this.props.brick.validators.find(v => v.type === BrickValidatorType.required);
            if (!requiredValidator) {
                this.props.brick.validators.push({
                    type: BrickValidatorType.required,
                    rule: null,
                    bricksForValidation: null,
                    errorMessage: thisBrick.triggerMessage
                });
            } else {
                requiredValidator.errorMessage = thisBrick.triggerMessage;
            }
        }
        if (thisBrick.action === Action.markAsOptional) {

            this.props.brick.validators = this.props.brick.validators.filter(v => v.type !== BrickValidatorType.required);
        }
        let errorMessages = await functions.validateBrickAsync(this.props.brick, value, this.props.dataToValidate, this.props.appPath);
        if (value) {
            const parsedFiles = JSON.parse(value);
            if (parsedFiles.filter((file) => file.ErrorMessage).length > 0) {
                errorMessages.add(this.context.translations.FileUploadError)
            } else {
                errorMessages.delete(this.context.translations.FileUploadError)
            }
            if (!parsedFiles.any()) {
                value = '';
            }
        }

        (await functions.validateBrickAsync(this.props.brick, value, this.props.dataToValidate, this.props.appPath)).forEach(error => errorMessages.add(error));

        if (thisBrick.action === Action.hide) {
            value = this.props.brick.value;
            errorMessages = new Set<string>();
        }

        this.setState(
            {
                touched: true,
                value: value,
                hasErrors: errorMessages.size > 0 || (thisBrick && thisBrick.hasErrors),
            },
            () => this.context.updateDataToSend(this.props.brick.key, value, errorMessages, submit));
    }

    handleFileUpload(): void {
        const formData = new FormData();
        Array.from(this.fileInput.current.files).forEach((file, index) => {
            const extension: string = file.name.substring(file.name.lastIndexOf('.') + 1);
            let hasErrors = false;
            if (!this.isFileTypeSupported(extension)) {
                this.state.files.add({
                    ErrorMessage: this.context.translations.FileTypeError,
                    Name: file.name,
                    ServerFileName: uuidv4()
                });
                hasErrors = true;
            }
            if (file.size > this.context.fileUploadValidation.maxFileSize * 1024) {
                this.state.files.add({
                    ErrorMessage: this.context.translations.FileSizeError,
                    Name: file.name,
                    ServerFileName: uuidv4()
                });
                hasErrors = true;
            }
            if (!hasErrors) {
                formData.append('file' + index, file);
            }
        });

        formData.append('__RequestVerificationToken', this.context.antiForgeryToken.split('"')[5]);
        this.setState({ loadingFiles: true });
        fetch(this.uploadUrl, {
            method: 'POST',
            body: formData
        })
            .then(response => {
                if (response.status !== 200) {
                    this.setState(
                        {
                            loadingFiles: false,
                            files: [{
                                Name: '',
                                ErrorMessage: this.context.translations.SingleFileUploadError,
                                UniqueName: uuidv4()
                            }]
                        });
                } else {
                    response.json()
                        .then(data => {
                            Array.from(data.files).forEach((file: any) => {
                                if (file.IsMalware) {
                                    this.state.files.add({
                                        ErrorMessage: this.context.translations.Infected,
                                        Name: file.Name,
                                        UniqueName: uuidv4()
                                    });
                                } else {
                                    file.UniqueName = uuidv4();
                                    this.state.files.add(file);
                                }
                            })
                            this.setState({
                                files: this.state.files,
                                loadingFiles: false
                            }, async () => await this.handleChange(JSON.stringify(this.state.files), false));
                        });
                }
                this.fileInput.current.blur();
            });

    }

    handleClickOutside() {
        this.tooltip.current._tippy.hide();
    }

    render() {
        if (this.state.action === Action.hide) {
            return null;
        }
        return (
            <div className="l-section l-sectionReadonly">
                <div className="l-sectionLeft">
                    <span className="l-section-text">
                        {this.props.brick.previousModification &&
                            <Tippy trigger="focus" arrow={true} theme="quipu" maxWidth="225" delay={200} animation="shift-away" hideOnClick={false} content={this.context.translations.WarningUpdatePendingGeneric}>
                                <img src={this.emptyImgSrc} className="pointer border0 imgWarning valignmiddle inline-ico" tabIndex={0} />
                            </Tippy>
                        }
                        <label className={(this.props.brick.readOnly ? 'readonly' : '')} htmlFor={this.props.brick.key}>{this.props.brick.title}</label>
                        {this.props.brick.tooltip &&
                            <Tippy ref={this.tooltip} trigger="focus" interactive={true} arrow={true} theme="quipu" maxWidth="225" delay={200} animation="shift-away" hideOnClick={false} content={<span onClick={() => this.handleClickOutside()} dangerouslySetInnerHTML={{ __html: this.props.brick.tooltip.replace('<a', '<a tabindex="-1"') }} />}>
                                <img src={this.emptyImgSrc} className="pointer border0 tooltip-ico valignmiddle" tabIndex={0} />
                            </Tippy>
                        }
                    </span>
                </div>
                <div className="l-sectionRight">
                    <ul className="usuallist l-section-text vmediumseparationextramargin">
                        {this.state.files.map((file) =>
                            <FileUploadListItem key={file.UniqueName ?? file.ServerFileName} file={file} handleFileDeletion={this.handleFileDeletion.bind(this)} appPath={this.props.appPath} />
                        )}
                    </ul>
                    <input id={this.props.brick.key} type="file" multiple className="custominputfile" name="files[]" ref={this.fileInput} onChange={this.handleFileUpload} accept={this.state.supportedFileTypes.map((type) => '.' + type).join(',')}
                        onClick={(event) => {
                            (event.target as HTMLInputElement).value = null;
                        }} />
                    <label className={this.state.loadingFiles ? 'loading marginright' : 'marginright'} htmlFor={this.props.brick.key}>{this.context.translations.UploadFiles}</label>
                    {this.state.touched && this.state.hasErrors && <span className="field-validation-error">*</span>}
                </div>
            </div>
        );
    }
}