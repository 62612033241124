import BrickState, { BrickProps } from './types/BrickTypes';
import { Brick, BrickValidatorType, BaseBrick, BaseType, BrickValidator } from './types/DTOs';

const toDate = function (format, date) {
    const normalized = date.replace(/[^a-zA-Z0-9]/g, '-');
    const normalizedFormat = format.toLowerCase().replace(/[^a-zA-Z0-9]/g, '-');
    const formatItems = normalizedFormat.split('-');
    const dateItems = normalized.split('-');

    const monthIndex = formatItems.indexOf('mm');
    const dayIndex = formatItems.indexOf('dd');
    const yearIndex = formatItems.indexOf('yyyy');
    const hourIndex = formatItems.indexOf('hh');
    const minutesIndex = formatItems.indexOf('ii');
    const secondsIndex = formatItems.indexOf('ss');

    const today = new Date();

    const year = yearIndex > -1 ? dateItems[yearIndex] : today.getFullYear();
    const month = monthIndex > -1 ? dateItems[monthIndex] - 1 : today.getMonth() - 1;
    const day = dayIndex > -1 ? dateItems[dayIndex] : today.getDate();

    const hour = hourIndex > -1 ? dateItems[hourIndex] : today.getHours();
    const minute = minutesIndex > -1 ? dateItems[minutesIndex] : today.getMinutes();
    const second = secondsIndex > -1 ? dateItems[secondsIndex] : today.getSeconds();

    return new Date(year, month, day, hour, minute, second);
};

//Returns false when validation fails
const comparisonOperators = (brick: Brick, value: string, brickValidator: BrickValidator): boolean => {
    switch (brick.baseType) {
        case BaseType.dateTime:
            const valueDate = toDate(brick.format, value);
            const valueDateFromValidator = toDate(brick.format, brickValidator.rule);
            switch (brickValidator.type) {
                case BrickValidatorType.greaterThan:
                    return valueDate <= valueDateFromValidator;
                case BrickValidatorType.greaterThanOrEqual:
                    return valueDate < valueDateFromValidator;
                case BrickValidatorType.lowerThan:
                    return valueDate >= valueDateFromValidator;
                case BrickValidatorType.lowerThanOrEqual:
                    return valueDate > valueDateFromValidator;
                default:
                    return false;
            }
        case BaseType.number:
            const valueNumber = Number.parseInt(value);
            const valueNumberFromValidator = Number.parseInt(brickValidator.rule);
            switch (brickValidator.type) {
                case BrickValidatorType.greaterThan:
                    return valueNumber <= valueNumberFromValidator;
                case BrickValidatorType.greaterThanOrEqual:
                    return valueNumber < valueNumberFromValidator;
                case BrickValidatorType.lowerThan:
                    return valueNumber >= valueNumberFromValidator;
                case BrickValidatorType.lowerThanOrEqual:
                    return valueNumber > valueNumberFromValidator;
                default:
                    return false;
            }
        default:
            switch (brickValidator.type) {
                case BrickValidatorType.greaterThan:
                    return value <= brickValidator.rule;
                case BrickValidatorType.greaterThanOrEqual:
                    return value < brickValidator.rule;
                case BrickValidatorType.lowerThan:
                    return value >= brickValidator.rule;
                case BrickValidatorType.lowerThanOrEqual:
                    return value > brickValidator.rule;
                default:
                    return false;
            }
    }
}

const validateBrickAsync = async (brick: Brick, value: string, dataToValidate: BaseBrick[], appPath: string): Promise<Set<string>> => {
    const result = new Set<string>();

    const regularExpression = brick.validators.firstOrDefault(validator => validator.type === BrickValidatorType.regularExpression);
    if (regularExpression) {
        const regex = new RegExp(regularExpression.rule.replace(/\\\\/g, '\\'), 'i');
        !regex.test(value) && value !== '' && result.add(regularExpression.errorMessage);
    }

    const isRequired = brick.validators.firstOrDefault(validator => validator.type === BrickValidatorType.required);
    if (isRequired) {
        value === '' && result.add(isRequired.errorMessage);
    }

    const lowerThan = brick.validators.firstOrDefault(validator => validator.type === BrickValidatorType.lowerThan);
    if (lowerThan && comparisonOperators(brick, value, lowerThan)) {
        result.add(lowerThan.errorMessage);
    }

    const lowerThanOrEqual = brick.validators.firstOrDefault(validator => validator.type === BrickValidatorType.lowerThanOrEqual);
    if (lowerThanOrEqual && comparisonOperators(brick, value, lowerThanOrEqual)) {
        result.add(lowerThanOrEqual.errorMessage);
    }

    const greaterThan = brick.validators.firstOrDefault(validator => validator.type === BrickValidatorType.greaterThan);
    if (greaterThan && comparisonOperators(brick, value, greaterThan)) {
        result.add(greaterThan.errorMessage);
    }

    const greaterThanOrEqual = brick.validators.firstOrDefault(validator => validator.type === BrickValidatorType.greaterThanOrEqual);
    if (greaterThanOrEqual && comparisonOperators(brick, value, greaterThanOrEqual)) {
        result.add(greaterThanOrEqual.errorMessage);
    }

    const requiredIfAny = brick.validators.firstOrDefault(validator => validator.type === BrickValidatorType.requiredIfAnyChanged);
    if (requiredIfAny && value === '') {
        let anyChanged = false;
        requiredIfAny.bricksForValidation.filter(b => b !== brick.key).map(b => {
            const locatedField = dataToValidate.find((data) => data.key === b);
            if (locatedField.isChanged) {
                anyChanged = true;
            }
        });
        anyChanged && result.add(requiredIfAny.errorMessage);
    }

    return result;
};

const checkIfBrickNeedsUpdate = (prevProps: BrickProps, nextProps: BrickProps, prevState: BrickState, nextState: BrickState): boolean => {
    const brick = prevProps.brick; //brick won't change, so we can take either prev or next

    if (brick.readOnly) {
        return false;
    }

    if (!prevProps.touch && nextProps.touch && (nextProps.touch !== nextState.touched)) {
        return true;
    }

    if (prevState.action !== nextState.action) {
        return true;
    }

    const prevBrick = prevProps.dataToValidate.firstOrDefault(b => b.key === prevProps.brick.key);
    const nextBrick = nextProps.dataToValidate.firstOrDefault(b => b.key === nextProps.brick.key);
    if (prevBrick.hasErrors !== nextBrick.hasErrors && (nextBrick.hasErrors !== nextState.hasErrors)) {
        return true;
    }

    if (prevBrick.action !== nextBrick.action) {
        return true;
    }

    const anySpecialValidator = brick.validators.firstOrDefault(validator =>
        validator.type === BrickValidatorType.requiredIfAnyChanged);

    if (!anySpecialValidator) {
        return false;
    }

    const requiredIfAny = brick.validators.firstOrDefault(validator =>
        validator.type === BrickValidatorType.requiredIfAnyChanged);

    let result = false;
    if (requiredIfAny) {
        requiredIfAny.bricksForValidation.forEach(brickKey => {
            const prevBrick = prevProps.dataToValidate.firstOrDefault(b => b.key === brickKey);
            const nextBrick = nextProps.dataToValidate.firstOrDefault(b => b.key === brickKey);
            if (prevBrick.isChanged !== nextBrick.isChanged) {
                result = true;
            }
        })
    }

    return result;
}

const checkIfBrickNeedsRerender = (prevProps: BrickProps, nextProps: BrickProps, prevState: BrickState, nextState: BrickState) => {
    if (prevState.value !== nextState.value) {
        return true;
    }

    if (!prevState.touched && nextState.touched) {
        return true;
    }

    const prevBrick = prevProps.dataToValidate.firstOrDefault(b => b.key === prevProps.brick.key);
    const nextBrick = nextProps.dataToValidate.firstOrDefault(b => b.key === nextProps.brick.key);
    if (prevBrick.hasErrors !== nextBrick.hasErrors) {
        return true;
    }

    if (prevState.hasErrors !== nextState.hasErrors) {
        return true;
    }

    return checkIfBrickNeedsUpdate(prevProps, nextProps, prevState, nextState);
}

export default {
    validateBrickAsync,
    checkIfBrickNeedsRerender,
    checkIfBrickNeedsUpdate,
    toDate
}
