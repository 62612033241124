const urlContent = (appPath: string, path: string) : string => {
    return appPath + path.replace(/^\//, '');
}
const isValidDate = (dateTocheck: any) : boolean => {
    return !isNaN(dateTocheck) && dateTocheck instanceof Date;
}

export default {
    urlContent,
    isValidDate
}