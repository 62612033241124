import React from 'react';
import ClientDataBitBrick from './ClientDataBitBrick';
import ClientDataSelectBrick from './ClientDataSelectBrick';
import ClientDataTextBrick from './ClientDataTextBrick';
import ClientDataTermsAndConditionsBrick from './ClientDataTermsAndConditionsBrick';
import ClientDataDateTimeBrick from './ClientDataDateTimeBrick';
import ClientDataFileUploadBrick from './ClientDataFileUploadBrick';
import ClientDataContextObject from './ClientDataContext';
import { BaseType } from './types/DTOs';
import { GroupProps } from './types/GroupTypes';

export default class ClientDataGroup extends React.Component<GroupProps, any> {

    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className="box-section extramargin">
                {this.props.group.title && <div className="l-section l-sectionReadonlyHeader">{this.props.group.title}</div>}
                {this.props.group.textBefore && <div className="l-section l-sectionReadonly extrapadding">{this.props.group.textBefore}</div>}
                {this.props.group.rows.map((row, index) =>
                    (
                        <div key={'row' + index} className={row.bricks.length > 1 ? 'clientDataRow' : 'clientDatasRow'}>
                            {row.bricks.map((brick) => {
                                return (
                                    <ClientDataContextObject.Consumer key={brick.key}>
                                        {context => {
                                            switch (brick.baseType) {
                                                case BaseType.file:
                                                    return (
                                                        <ClientDataFileUploadBrick
                                                            brick={brick}
                                                            dataToValidate={this.props.dataToValidate}
                                                            touch={this.props.touchBricks}
                                                            appPath={context.appPath}
                                                        />);
                                                case BaseType.dateTime:
                                                    return (
                                                        <ClientDataDateTimeBrick
                                                            brick={brick}
                                                            dataToValidate={this.props.dataToValidate}
                                                            touch={this.props.touchBricks}
                                                            appPath={context.appPath}
                                                        />);
                                                case BaseType.termsAndConditions:
                                                    return (
                                                        <ClientDataTermsAndConditionsBrick
                                                            brick={brick}
                                                            dataToValidate={this.props.dataToValidate}
                                                            touch={this.props.touchBricks}
                                                            appPath={context.appPath}
                                                        />);
                                                case BaseType.bit:
                                                    return (
                                                        <ClientDataBitBrick
                                                            brick={brick}
                                                            dataToValidate={this.props.dataToValidate}
                                                            touch={this.props.touchBricks}
                                                            appPath={context.appPath}
                                                        />);
                                                case BaseType.selectList:
                                                    return (
                                                        <ClientDataSelectBrick
                                                            brick={brick}
                                                            dataToValidate={this.props.dataToValidate}
                                                            touch={this.props.touchBricks}
                                                            appPath={context.appPath}
                                                            emptyDropdownLabel={context.translations.EmptyDropdownLabel}
                                                        />);
                                                default:
                                                    return (
                                                        <ClientDataTextBrick
                                                            brick={brick}
                                                            dataToValidate={this.props.dataToValidate}
                                                            touch={this.props.touchBricks}
                                                            appPath={context.appPath}
                                                        />);
                                            }
                                        }}
                                    </ClientDataContextObject.Consumer>);
                            })}
                        </div>
                    )
                )}
                {this.props.group.textAfter && <div className="l-section l-sectionReadonly extrapadding">{this.props.group.textAfter}</div>}
            </div>
        );
    }
}